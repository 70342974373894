
/*
 * RISE 
 * GTM Utilities 
 * 
 * Registers click event handlers on elements tagged [wfu-gtm-event=click]
 * Locates an inner <script type="sa5/gtm-data"> element,
 * and pushes that object to the GTM dataLayer 
 */
import { GTMEventElement } from "./gtm-event-element";

 

// Ensure the dataLayer array exists
declare global {
    interface Window {
      dataLayer: any[];
    }
}
window.dataLayer = window.dataLayer || [];

export class GTM {

    gtmEvents: GTMEventElement[] = [];

    constructor() {
    }


    safeInit() {

        try {
            this.init();
        } catch (error) {
            console.error("An unexpected error occurred in GTM:", error);
        }
        
    }    

    // Initialize all [wfu-gtm-event] elements on the page
    init() {

        // Setup load event handlers
        document.querySelectorAll("[wfu-gtm-event='load']").forEach((element) => {

            const event = new GTMEventElement(element as HTMLElement);
            event.init();

            console.debug("GTM load event found", event.data);

            // Add to collection
            this.gtmEvents.push(event);

            // Invoke now
            event.invokeEvent();

        });

        // Setup click event handlers
        document.querySelectorAll("[wfu-gtm-event='click']").forEach((element) => {

            const event = new GTMEventElement(element as HTMLElement);
            event.init();

            console.debug("GTM click event found", event.data);

            // Add to collection
            this.gtmEvents.push(event);


            console.debug("Setting up GTM click event trigger");

            // Detect close button clicks,
            // and then suppress the targeted element 
            element.addEventListener('click', () => {

                event.invokeEvent();

            });

        });
      
    }
}

   
    
    
    
    