

export interface WebflowBreakpoint {
    name: string;
    min: number; // absolute range
    max: number; // absolute range
    xMin: number; // eXtended range
    xMax: number; // eXtended range
}

const breakpoints: { [key: string]: WebflowBreakpoint } = {

    /**
     * Mobile Portrait
     */
    P: { name: 'Mobile Portrait', min: 0, max: 480, xMin: 0, xMax: 480 },

    /**
     * Mobile Landscape
     */
    L: { name: 'Mobile Landscape', min: 481, max: 767, xMin: 0, xMax: 767 },
    T: { name: 'Tablet', min: 768, max: 991, xMin: 0, xMax: 991 },
    D: { name: 'Desktop', min: 992, max: 1280, xMin: 992, xMax: Infinity },
    M: { name: 'Medium Large', min: 1281, max: 1440, xMin: 1281, xMax: Infinity },
    R: { name: 'Large', min: 1441, max: 1920, xMin: 1441, xMax: Infinity },
    X: { name: 'Extra Large', min: 1921, max: Infinity, xMin: 1921, xMax: Infinity },
};


/**
 * Webflow breakpoint information class
 */
export class WebflowBreakpointHandler {

    /**
     * Returns the current Breakpoint definition 
     */
    get currentBreakpoint(): WebflowBreakpoint | undefined {
        const screenWidth = window.innerWidth;
        return Object.values(breakpoints)
            .find(
                breakpoint => screenWidth >= breakpoint.min && screenWidth <= breakpoint.max
                );
    }

    /**
     * Returns true if the current breakpoint matches
     * @param breakpoint 
     * @param useExtendedRange 
     * @returns 
     */
    currentBreakpointMatches(breakpoint: WebflowBreakpoint, useExtendedRange: boolean = false): boolean {
        const width = window.innerWidth;
        const rangeMin = useExtendedRange ? breakpoint.xMin : breakpoint.min;
        const rangeMax = useExtendedRange ? breakpoint.xMax : breakpoint.max;
        return width >= rangeMin && width <= rangeMax;
    }

    /**
     * Returns true if the current breakpoint matches
     * @param input - Breakpoint specification string
     * @returns True if the current breakpoint matches
     */
    currentBreakpointMatchesSpec(input: string): boolean {
        return this.findMatchingBreakpoint(input) != undefined; 
    }

    /**
     * Determines the first matching breakpoint in the spec string
     * @param input - Breakpoint spec string
     * @returns Breakpoint matched, or undefined
     */
    findMatchingBreakpoint(input: string): WebflowBreakpoint | undefined {
        for (const letter of input) {
          const isLowerCase = letter === letter.toLowerCase();
          const key = isLowerCase ? letter.toUpperCase() : letter;
          const breakpoint = breakpoints[key];
          if (breakpoint && this.currentBreakpointMatches(breakpoint, isLowerCase)) {
            return breakpoint;
          }
        }
        return undefined;
    }

}
