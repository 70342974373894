
/*
 * RISE 
 * Page | Partner 
 */
import { Sa5UtmLink } from "./utm";

 

export class Sa5SocialShareController {

    constructor() {
    }
  
    init() {

console.log("social share")

        // Look for all links with a [code] attribute
        // and apply it to the link's querystring as ?code=VALUE. 

        // Get all links with the 'code' attribute
        const social: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[wfu-socialshare]');

        social.forEach((link: HTMLAnchorElement) => {
            // Get the value of the 'code' attribute
            const platform: string = link.getAttribute('wfu-socialshare') || '';
            switch (platform) {

                case "email":

                    const subject: string = link.getAttribute('wfu-socialshare-subject') || '';
                    let message: string = link.getAttribute('wfu-socialshare-message') || '';
                    
                    // Get the current URL of the page
                    let currentURL: string = window.location.href;

                    // Apply UTM params if they're specified 
                    currentURL = Sa5UtmLink.applyUTMParameters(currentURL, link);

                    // Append line breaks and the current URL to the message
                    message = message.replace(/\\n/g, '\n');
                    message += `\n\n${currentURL}`;

                    // Encode the subject and message for the mailto link
                    const encodedSubject = encodeURIComponent(subject);
                    const encodedMessage = encodeURIComponent(message);

                    // Create the mailto link
                    const mailtoLink = `mailto:?subject=${encodedSubject}&body=${encodedMessage}`;

                    // Log or use the mailto link
                    console.log(mailtoLink);

                    // Example usage: setting the href attribute of an anchor element
                    link.setAttribute('href', mailtoLink);
                    
                    break;
                default:
                    console.error("Unsupported SA5 social share platform: ", platform); 
                    break;

            }

        });
                    
    }

}

    
    

    
    
    
    