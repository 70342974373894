
/*
 * RISE 
 * Desktop CTA Utilities 
 * 
 * Constructs Adjust tracking Urls
 * for each banner. 
 */ 

import { AdjustTrackingQr } from './adjust-qr';


export class DesktopCta {

    constructor() {
    }

    safeInit() {

      try {
          this.init();
      } catch (error) {
          console.error("An unexpected error occurred in Desktop CTA:", error);
      }
      
    }

    init() {

      /**
       * QR Code on CTA
       */ 
      try {
      
        const canvases = document.querySelectorAll("[cta-qr]");
        canvases.forEach((canvasElement) => {

          if(!canvasElement)
            return;
          if(!(canvasElement instanceof HTMLCanvasElement))
            return; // continue

          const canvas = canvasElement as HTMLCanvasElement;
          const qrElement: HTMLElement = canvasElement.parentElement as HTMLElement;

          const qr: AdjustTrackingQr = new AdjustTrackingQr();
          qr.loadFromElement(qrElement); // canvasElement.parentElement as HTMLElement);

          // If a size is specified, use that
          // default to 100px 
          qr.size = Number(qrElement.getAttribute('qr-size')) || 100;

          qr.generate(canvas); 

          // Set trigger timing 
          const DESKTOP_CTA_TRIGGER_MS: number = 10000; // 10 seconds
          console.log('DESKTOP_CTA_TRIGGER_MS', DESKTOP_CTA_TRIGGER_MS);
          setTimeout(function() {
            var button = document.getElementById('cta-desktop-trigger');
            if (button) {
  //              button.click();

                const event = new MouseEvent('click', {
                  bubbles: true,
                  cancelable: true,
                  view: window
                });
                button.dispatchEvent(event);


            }
            console.log('Triggered CTA Desktop Button.')
          }, DESKTOP_CTA_TRIGGER_MS); // 10 seconds

        });

      } catch (error) {
        console.error("An unexpected error occurred in CTA Desktop:", error);
      }
                    
    }

}

    
    

    
    
    
    