
/*
 * RISE 
 * Modal Suppression
 * 
 * The main function of this library is to suppress modals or other elements
 * for a certain period of time. 
 */ 



// Global Rise object
// window['Rise'] = window['Rise'] || {}; 
// var Rise = window['Rise']; 

export class Modal {

//    _modal: HTMLElement = undefined;

    constructor() {
    }
        
    // Generates the unique key name for suppression tracking
    getModalKeyName(name: string) {
        return `wfu-modal_${name}`; 
    }

    // Returns true if the item is flagged as suppressed
    isSuppressed(name: string) {
      
    //  const suppressed = Cookies.get(
    //    getKeyName(name)
    //  );
      
    //  const suppressed = getCookie(
    //    getKeyName(name)
    //  );

        // We use session storage
        // suppression is temporary, to the current tab-session 
        const suppressed = sessionStorage.getItem(
            this.getModalKeyName(name)  
            ); 
        
        return suppressed;
    }

    // Suppress the item
    suppress(name: string, val: string, duration: string) {
      
    //  Cookies.set(
    //    getKeyName(name), 
    //    val, 
    //    { expires: duration // days 
    //    }
    //  );
      
    //  setCookie(
    //    getKeyName(name), 
    //    val, 
    //    duration // days 
    //  );
      
        // We'll use session storage
        // suppression is temporary, to the current tab-session 
        sessionStorage.setItem(
            this.getModalKeyName(name), val);
      
    }

    // Un-suppress the item
    unSuppress(name: string) {
      
    //    Cookies.remove(getKeyName(name));
    //  deleteCookie(getKeyName(name)); 
      
        // We'll use session storage
        // suppression is temporary, to the current tab-session 
        sessionStorage.removeItem(this.getModalKeyName(name));
    }

    safeInit() {

        try {
            this.init();
        } catch (error) {
            console.error("An unexpected error occurred in Modal:", error);
        }
        
    }

    // Initialize all "modals" on the page
    init() {

// console.log("modal-init");


        // Remove any elements that are cookie-suppressed
        document.querySelectorAll("[wfu-modal-trigger='load']").forEach((element) => {

            const modal = element as HTMLElement;
            const modalName = modal.getAttribute("wfu-modal") || "default";

            // Check suppression 
            if (this.isSuppressed(modalName)) {
                modal.remove();
                return;
            }
    
            // If this article is being browsed from our in-app browser, hide the sticky banner
            let intervalCtr = 0;
            const myInterval = setInterval(() => {
                if ((window as any)._riseappbrowser === true) {
                    modal.remove();
                    console.log("suppressed due to in-app browser.");
                    clearInterval(myInterval);
                } else if (intervalCtr > 3) {
                    clearInterval(myInterval);
                }
                intervalCtr += 1;
            }, 250);
    
            // Un-hide the item, if not suppressed
            modal.removeAttribute("wfu-modal-trigger");
        });

        // Detect close button clicks,
        // and then suppress the targeted element 
        document.querySelectorAll("[wfu-modal-close]").forEach((element) => {
            
            // Detect close button clicks,
            // and then suppress the targeted element 
            element.addEventListener('click', () => {

                // Get modal
                const modalClose = element; //this as HTMLElement;
                const modal = modalClose.closest("[wfu-modal]") as HTMLElement;
                const modalName = modal.getAttribute("wfu-modal") || "default";
                const modalCloseVal = modalClose.getAttribute("wfu-modal-close") || "true";
                const modalCloseType = modalClose.getAttribute("wfu-modal-close-type") || "auto";
                const modalSuppressDuration = modalClose.getAttribute("wfu-modal-suppress-days") || "9999";
            
                // Set cookie suppression 
                this.suppress(
                    modalName, 
                    modalCloseVal, 
                    modalSuppressDuration // days
                );
            
                // Close dialog
                // TODO: consider special interaction closes, do nothing 
                switch(modalCloseType) {
                    case "interaction":
                        // do nothing
                        break;
                    case "auto":
                    default:
                        // remove now 
                        modal.remove(); 
                        break;
                }

            });

        });

        // Remove any elements that are cookie-suppressed
        document.querySelectorAll("[wfu-modal-close-trigger]").forEach((element) => {

            const modalTrigger = element as HTMLElement;
//            const modalName = modal.getAttribute("wfu-modal") || "default";
            const triggerType = element.getAttribute("wfu-modal-close-trigger");
            const triggerTypeConfig: string | null = element.getAttribute("wfu-modal-close-trigger-config");

            console.log("close trigger")
            console.log(triggerType)
            console.log(triggerTypeConfig)

            switch(triggerType) {
                case "timer": {

                    const timerDuration: number = parseInt(triggerTypeConfig as string);

                    // Create timer, ms from config
                    // Delete element on close ( later, just hide )

                    let intervalCtr = 0;
                    const myInterval = setInterval(() => {
                        modalTrigger.remove(); 
                    }, timerDuration);

                    break;
                }
            }
    
        }); 

    }

}
    
    
    

    
    
    
    