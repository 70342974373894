/*
 * RISE
 * Main entry point
 * 
 */

import { VERSION } from "./version";
import { routeDispatcher } from "./routes";
import { initSSE } from "@sygnal/sse"; 

import { Linkify } from './linkify';
import { Modal } from './modal';
import { Banner } from './banner';
import { ScrollFix } from './scrollfix';
import { TocFix } from './tocfix';
import { PartnerPage } from './page/partner';
import { BlogPage } from './page/blog';
import { VideoPage } from './page/video';
import { TestPage } from './page/test';
import { DesktopCta } from './cta-desktop';
import { GTM } from './gtm';
import { ExperimentConfig } from "./experiment";

// type RouteHandler = () => void;

// interface Routes {
//     [path: string]: RouteHandler;
// } 

interface SiteGlobalDataType {
    // Define properties and their types for SiteDataType
    // For example:
    // someProperty?: string;
    // anotherProperty?: number;
    // Add other properties as needed
}

// Global vars
const SITE_NAME = 'Site';


// const routes: Routes = {
//     '/': () => {
//         console.log('This is the homepage.');
//         // Your function for the homepage
//     },
//     '/blog/*': () => {
//         console.log('This is a blog article.');
//         // Your function for the about page
//     },
// };

// Extend the Window interface to include globals
// as a Typescript accessibility convenience
declare global {
    interface Window {

        // fsAttributes
        fsAttributes: [string, (filterInstances: any[]) => void][];

        // Site global data
        Site: SiteGlobalDataType;

        // Experiment global data 
        Experiment: ExperimentConfig;

    }
}

// Global Rise object
// window['Rise'] = window['Rise'] || {}; 
// var Rise = window['Rise'];

//var Webflow = Webflow || [];
//Webflow.push(function() {





// Init SSE Engine
initSSE();

// Perform setup, sync
const setup = () => {
    
    console.log(`${SITE_NAME} package init v${VERSION}`);
    
    routeDispatcher().setupRoute(); 

}

// Perform exec, async
// After DOM content loaded 
const exec = () => {
    
    routeDispatcher().execRoute(); 

}




// // Auto-execute on DOM load 
// document.addEventListener("DOMContentLoaded", init)


/**
 * Initialize
 */

// Perform setup, sync
setup();

// Perform exec, async
if (document.readyState !== 'loading') {
    exec();
} else {
    document.addEventListener("DOMContentLoaded", exec);
}