/*
 * SITE
 * Main entry point
 * 
 * https://engine.sygnal.com/
 * 
 * ENGINE MODE
 * ?engine.mode=dev
 * ?engine.mode=prod
 * 
 */

import { PartnerPage } from "./page/partner";
import { VideoPage } from "./page/video";
import { BlogPage } from "./page/blog";

import { RouteDispatcher } from "@sygnal/sse";
import { Site } from "./site";

export const routeDispatcher = (): RouteDispatcher => {
    
    var routeDispatcher = new RouteDispatcher(Site);
    routeDispatcher.routes = {

        // Site pages 
        '/partner/*': PartnerPage,
        '/video/*': VideoPage,
        '/blog/*': BlogPage,

        // TEST Pages

    };

    return routeDispatcher;
}

