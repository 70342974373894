
/*
 * RISE 
 * Page | Partner 
 */ 
import { IModule } from "@sygnal/sse";

export class PartnerPage implements IModule {

    constructor() {
    }
    
    setup() {
        
    }
  
    exec() {

        // Look for all links with a [code] attribute
        // and apply it to the link's querystring as ?code=VALUE. 

        // Get all links with the 'code' attribute
        const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[code]');

        links.forEach((link: HTMLAnchorElement) => {
            // Get the value of the 'code' attribute
            const codeValue: string = link.getAttribute('code') || '';

            // Use the URL interface to handle the href
            const url: URL = new URL(link.href);
            
            // Use URLSearchParams to set the code parameter
            url.searchParams.set('code', codeValue);

            // Update the link href
            link.href = url.toString();
        });
                    
    }

}

    
    

    
    
    
    