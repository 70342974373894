
/*
 * RISE 
 * GTM Utilities 
 * 
 * Registers click event handlers on elements tagged [wfu-gtm-event=click]
 * Locates an inner <script type="sa5/gtm-data"> element,
 * and pushes that object to the GTM dataLayer 
 */
import { WebflowBreakpointHandler } from "./breakpoint";

 

// Ensure the dataLayer array exists
declare global {
    interface Window {
      dataLayer: any[];
    }
}
window.dataLayer = window.dataLayer || [];

export class GTMEvent {

    // Name is primarily for debug logging and reference purposes
    // and is not part of the GTM event data package  
    name: string | null = null;

    // The GTM event data
    data: any; 

    defaultData: any = {
        event: undefined,
        event_category: undefined,
        event_label: undefined,
        component_name: undefined,
        interaction_type: undefined,
        position: undefined,
        variant: undefined
    }

    triggerType: string | null = null; // null is direct invocation 

    // DTLPdtlp + larger 
    breakpointSpec: string | null = null;

    valid: boolean = false;

    constructor() {

    }

    init() {

        // // Get triggerType
        // if (this.elem.hasAttribute('wfu-gtm-event')) {
        //     this.triggerType = this.elem.getAttribute('wfu-gtm-event');
        // }

        // // Get name
        // if (this.elem.hasAttribute('wfu-gtm-event-name')) {
        //     this.name = this.elem.getAttribute('wfu-gtm-event-name');
        // }

        // // Breakpoints 
        // if (this.elem.hasAttribute('wfu-gtm-event-breakpoints')) {
        //     this.breakpointSpec = this.elem.getAttribute('wfu-gtm-event-breakpoints');
        // } 

        // // Get the data 
        // this.data = this.getConfig(this.elem);

        // if (this.data)
        //     this.valid = true; 

    }

    // Invoke the GTM event
    invokeEvent(): void {

        // If there is a breakpoint restriction
        // suppress matches outside of that breakpoint 
        if(this.breakpointSpec) {
            const bp: WebflowBreakpointHandler = new WebflowBreakpointHandler();
            if (!bp.currentBreakpointMatchesSpec(this.breakpointSpec)) {
                console.debug(`GTM event suppressed (breakpoint) - ${this.name} (${this.triggerType})`); 
                return;
            }
        }

        // Fire GTM event 
//        window.dataLayer.push(this.data);

        /**
         * TESTING CODE
         */

        const dataClone = { ...this.data };

        // Remove properties that are undefined from the clone
        // Object.keys(dataClone).forEach(key => {
        //     if (dataClone[key] === undefined) {
        //         delete dataClone[key];
        //     }
        // });

        const mergedData = {
            ...this.defaultData,
            ...this.data, // Spread the properties of this.data
//            event: dataClone.event || "unnamed_event", 
//            event_data: dataClone // Include dataClone as event_data property
        };        

        const dataLayerData = {
            event: mergedData.event || "unnamed_event", 
            event_data: mergedData
        }

        // Use the modified clone in the dataLayer push
        window.dataLayer.push(dataLayerData);

        console.debug(
            `GTM EVENT TRIGGERED - ${this.name} (${this.triggerType})`, 
            dataLayerData
        ); 

    }

//     getConfig(elem: HTMLElement): any {

// /*
// <script type="sa5/gtm-data">
// {
//   event: 'banner_interaction',
//   banner_name: 'sticky',
//   interaction_type: 'click'
// }
// </script>
// */

//         // Find the descendant <script type="gtm-data"> element
//         const scriptElement: HTMLScriptElement | null = elem.querySelector('script[type="sa5/gtm-data"]');

//         if (!(scriptElement && scriptElement.textContent)) {
//             console.error('Script element with type "sa5/gtm-data" not found or contains no data.');
//             return;
//         }

//         try {

//             // Parse the JSON string inside the <script> element into an object
//             const dataObject: any = JSON.parse(scriptElement.textContent || '{}');
// //            console.log(dataObject); 

//             // Normalize nulls to undefined for GTM
//             Object.keys(dataObject).forEach(key => {
//                 if (dataObject[key] === null) {
//                 dataObject[key] = undefined;
//                 }
//             });

//             return dataObject;

//         } catch (error) {
//             console.error("GTM data parsing error:", error);
//             return;
//         }

//     }

}

   
    
    
    
    