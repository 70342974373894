
/*
 * RISE 
 * Page | Blog 
 * 
 * Important:
 * <link rel="stylesheet" href="https://cdn.plyr.io/3.7.8/plyr.css" />
 */

import { TocFix } from "../tocfix";
import Plyr from 'plyr'; 
import { AdjustTrackingQr } from "../adjust-qr";
import { Debug } from "../debug";
import { Linkify } from "../linkify";
import { IModule, Page } from "@sygnal/sse";
import { Sa5SocialShareController } from "../sa5/socialshare";
import { GTMEvent } from "../gtm-event";


//const url: string = 'https://www.sygnal.com?test';


export class BlogPage implements IModule {

    private debug: Debug = new Debug("blog", "RISE");

    constructor() {
    }

    setup() {

//        console.log("loading blog CSS"); 
//        Page.loadEngineCSS("blog.css"); 

      console.log("Blog | Setup");

    }
  
    exec() {

      console.log("Blog | Exec");
      
      (new Sa5SocialShareController).init(); 


//        this.debug.enabled = true; // Debug 

        /**
         * Add GTM tracking to social share links
         */

        try {

          // [fs-socialshare-element]
          // [wfu-socialshare]
          const socialShareLinks = document.querySelectorAll<HTMLAnchorElement>('a[fs-socialshare-element], a[wfu-socialshare]');
          socialShareLinks.forEach((link) => {
              link.addEventListener('click', (event) => {

                  const socialNetwork = link.getAttribute('fs-socialshare-element') || link.getAttribute('wfu-socialshare');
                  if (socialNetwork) {
                      this.socialShareGtmEvent(socialNetwork);
                  }
              });
          });
          
        } catch (error) {
          console.error("An unexpected error occurred in socialShare GTM tracking:", error);
        }

        /**
         * Fix links for the ToC 
         */

        try {

          let tocFix = new TocFix();
          tocFix.init(); 

          window.fsAttributes = window.fsAttributes || [];
          window.fsAttributes.push([
            'toc',
            (tableInstances) => {

              tocFix.addToCNavClickHandlers();

              this.debug.debug('toc Successfully loaded!');

            },
          ]);
          
        } catch (error) {
            console.error("An unexpected error occurred in tocFix:", error);
        }


        /**
         * Wire up components embedded
         * in Finsweet PRT RTB's
         */

        try {

          window.fsAttributes = window.fsAttributes || []; 
          window.fsAttributes.push([  
            'richtext',   
            (textInstances) => {   

              this.initAfterFsPRTLib();
              this.debug.debug('richtext Successfully loaded!');      
            },   
          ]);    

        } catch (error) {
          console.error("An unexpected error occurred in initAfterFsPRTLib:", error);
        }

        // // Look for all links with a [code] attribute
        // // and apply it to the link's querystring as ?code=VALUE. 

        // // Get all links with the 'code' attribute
        // const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[code]');

        // links.forEach((link: HTMLAnchorElement) => {
        //     // Get the value of the 'code' attribute
        //     const codeValue: string = link.getAttribute('code') || '';

        //     // Use the URL interface to handle the href
        //     const url: URL = new URL(link.href);
            
        //     // Use URLSearchParams to set the code parameter
        //     url.searchParams.set('code', codeValue);

        //     // Update the link href
        //     link.href = url.toString();
        // });
                    
    }

    // Invokes GTM event
    socialShareGtmEvent(componentName: string, variant: string | undefined = undefined) {

        // GTM 
        // Record click event 
        const gtm: GTMEvent = new GTMEvent();
        gtm.name = 'socialshare-click'; 
        gtm.data = {
//            event_data: {
            event: "custom_interaction",
            event_category: "User Interaction",
            event_label: "Social Share", 
            component_name: componentName, // "toc",
            interaction_type: "click",
            position: undefined,
            variant: variant
//            }
        };
//                gtm.breakpointSpec = 
        gtm.invokeEvent();         
  
    }
    
    initAfterFsPRTLib() {

      /**
       * Linkify
       */

      try {

        var linkify = new Linkify();
        linkify.init();

      } catch (error) {
        console.error("An unexpected error occurred in Linkify:", error);
      }

      /**
       * QR Code on Video CTA
       */ 
      try {

        const canvases = document.querySelectorAll("[video-cta-qr]");
        canvases.forEach((canvasElement) => {

          if(!(canvasElement instanceof HTMLCanvasElement))
            return; // continue

          const canvas = canvasElement as HTMLCanvasElement;

          const qr: AdjustTrackingQr = new AdjustTrackingQr();
          qr.loadFromElement(canvasElement);
          qr.generate(canvas); 
          
        });

      } catch (error) {
        console.error("An unexpected error occurred in Video CTA:", error);
      }

      /**
       * Popup + Video player
       */

      // IMPORTANT:
      // Make sure CSS is in the page... 
      // <link rel="stylesheet" href="https://cdn.plyr.io/3.7.8/plyr.css" /> 

// return;

      try {

        const players = document.querySelectorAll("[video-player]");
        players.forEach((elem) => {

          if(!(elem instanceof HTMLElement))
            return; // continue

          this.debug.debug("Video found- installing player"); 

          const player = new Plyr(elem, {});

      //          const player = new Plyr('#player', {});
        // if(player) {

          // Attach event listeners for play and pause events
          player.on('play', event => {
            this.debug.debug('video play');
          });

          player.on('pause', event => {
            this.debug.debug('video pause');
          });

          player.on('ended', event => {
            this.debug.debug('video ended.');
          });        

          //  * Setup mutationobserver to watch for close

          // Create a MutationObserver instance
          // SA5
          const observer = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((mutation: MutationRecord) => {
              if (mutation.attributeName === 'style') {
                // Assuming mutation.target is an HTMLElement
                const target = mutation.target as HTMLElement;
                const displayStyle = window.getComputedStyle(target).display;
                if (displayStyle === 'none') {
                  // Find the index of the hidden div
                  const index = Array.from(target.parentNode!.children).indexOf(target);
                  // Pause the corresponding video
                  player.pause();
                }
              }
            });
          });

          // Select all elements with the class 'fs_modal-1_popup' and observe each for changes
          document.querySelectorAll('.fs_modal-1_popup').forEach((elem, index) => {
            this.debug.debug("Installing observer for element at index:", index);
            observer.observe(elem, {
              attributes: true,
              attributeFilter: ['style'] // If you only want to observe changes to 'style' attribute
            });
          }); 

        });

      } catch (error) {
        console.error("An unexpected error occurred in Video Player:", error);
      }

    }

}  

    
    
    
    